import { Tab } from '@armis/armis-ui-library';
import { cloneDeep } from 'lodash';
import { PARTNER_TENANTS_API_MAPPING } from 'src/constants/APIResponse';
import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    TENANT_NAME,
    TENANT_URL,
    USERNAME,
    SECRET_KEY,
    CLIENT_ID,
    CLIENT_SECRET,
    EDIT,
    API_URL
} from 'src/constants/LabelText';
import { PartnerTenantsType } from 'src/types/APIResponseTypes';
import { FieldType } from 'src/types/CommonTypes';

export const initialData = {
    [TENANT_NAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [TENANT_URL]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [USERNAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [SECRET_KEY]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [CLIENT_ID]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [CLIENT_SECRET]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [API_URL]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    }
};

export const tabs: Tab[] = [
    {
        label: 'Configuration',
        tabValue: 'configuration'
    },
    {
        label: 'Credentials',
        tabValue: 'credentials'
    }
];

export const tabKeys = tabs.map(tab => tab.tabValue);

export const configurationInputs: FieldType[] = [
    {
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        labelName: TENANT_NAME,
        validations: {
            required: true,
            maxLength: 100
        }
    },
    {
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        labelName: TENANT_URL,
        validations: {
            required: true,
            maxLength: 500,
            validUrl: true
        }
    },
    {
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        labelName: USERNAME,
        validations: {
            maxLength: 100
        }
    }
];

export const centrixInputConfigurations: FieldType[] = [
    {
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        labelName: SECRET_KEY,
        inputType: PROPERTY_FIELD_TYPE_MAP.PASSWORD,
        validations: {
            maxLength: 500
        }
    }
];

export const viprInputConfigurations: FieldType[] = [
    {
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        labelName: CLIENT_ID,
        validations: {
            maxLength: 500
        }
    },
    {
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        labelName: API_URL,
        validations: {
            maxLength: 500,
            validUrl: true
        }
    },
    {
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        labelName: CLIENT_SECRET,
        inputType: PROPERTY_FIELD_TYPE_MAP.PASSWORD,
        validations: {
            maxLength: 1000
        }
    }
];

export const getInitialState = (
    mode: string,
    currentMenuItem: PartnerTenantsType | null
) => {
    if (mode === EDIT) {
        const clonedModalFields = cloneDeep(initialData);
        Object.keys(clonedModalFields!).forEach(element => {
            const apiKey = PARTNER_TENANTS_API_MAPPING[element];
            const valueOfElement: string | number =
                currentMenuItem![apiKey as keyof typeof currentMenuItem];
            clonedModalFields[element as keyof typeof clonedModalFields].value =
                valueOfElement ?? '';
        });
        return clonedModalFields;
    }
    return initialData;
};
