import { useState } from 'react';

import { Back, Button, Switch, TabGroup } from '@armis/armis-ui-library';
import { AxiosError, AxiosResponse } from 'axios';
import { TOAST_ID } from 'src/constants/APIConstants';
import { PARTNER_TENANTS_API_MAPPING } from 'src/constants/APIResponse';
import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    ADD,
    API_URL,
    ASSOCIATE_TENANT,
    BACK,
    CLIENT_ID,
    CLIENT_SECRET,
    EDIT,
    EDIT_TENANT,
    NEXT,
    PLACEHOLDER_PASSWORD,
    SUBMIT,
    TENANT_ADD_SUCCESS,
    TENANT_EDIT_SUCCESS,
    TENANT_URL,
    USERNAME
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE,
    urlFilter,
    validateFormFields,
    validateValues
} from 'src/helpers/utility';
import { Validators } from 'src/helpers/Validators';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import {
    StyledContainer,
    StyledSection
} from 'src/pages/containers/Partners/Partners.style';
import { AssociateTenantProps } from 'src/pages/containers/PartnerTenants/AssociateTenant/AssociateTenant.types';
import { editPartnerTenant, postPartnerTenant } from 'src/services/api.service';
import { ErrorResponse, PartnerTenantsType } from 'src/types/APIResponseTypes';
import { ModalFieldType, PropertyValidationType } from 'src/types/CommonTypes';

import {
    StyledCheckboxContainer,
    StyledCheckboxLabel,
    StyledDivider,
    StyledInputsContainer,
    StyledModal,
    StyledTabContent
} from './AssociateTenant.styles';
import {
    centrixInputConfigurations as centrixInputConfigs,
    configurationInputs as configurationInputConfigs,
    getInitialState,
    tabKeys,
    tabs,
    viprInputConfigurations as viprInputConfigs
} from './utils';

export const AssociateTenant = ({
    currentMenuItem,
    setIsLoading,
    modelOpen,
    getAllTenants,
    modalMode,
    partnerDetails,
    onCancel
}: AssociateTenantProps): JSX.Element => {
    const [modalFields, setModalFields] = useState<ModalFieldType>(() =>
        getInitialState(modalMode, currentMenuItem)
    );
    const [selectedTab, setSelectedTab] = useState(tabs[0].tabValue);
    const [isCentrixEnabled, setIsCentrixEnabled] = useState(
        currentMenuItem?.isCentrix ?? false
    );
    const [isVIPREnabled, setIsVIPREnabled] = useState(
        currentMenuItem?.isViprPro ?? false
    );
    const submitButtonLabel =
        selectedTab === tabKeys[tabKeys.length - 1] ? SUBMIT : NEXT;
    const modalTitle = modalMode === ADD ? ASSOCIATE_TENANT : EDIT_TENANT;
    const hasAnyError = Object.values(modalFields).some(({ error }) => error);

    const submitButtonClick = () => {
        if (
            !validateFormFields(0, setModalFields, modalFields, [
                configurationInputConfigs
            ])
        ) {
            const keysObject = Object.keys(modalFields);
            const dataObject = keysObject.reduce(
                (totalObject: any, currentValue) => {
                    if (
                        modalMode === ADD ||
                        currentValue === USERNAME ||
                        Validators.validateNotEmpty(
                            (modalFields[currentValue].value as string) ?? ''
                        )
                    ) {
                        let modalFieldValue = modalFields[currentValue].value;
                        if (currentValue === TENANT_URL) {
                            modalFieldValue = urlFilter(
                                modalFieldValue as string
                            );
                        }
                        totalObject[PARTNER_TENANTS_API_MAPPING[currentValue]] =
                            modalFieldValue;
                    }

                    return totalObject;
                },
                {}
            );
            dataObject.partner = { id: partnerDetails?.id };
            dataObject.isCentrix = isCentrixEnabled;
            dataObject.isViprPro = isVIPREnabled;

            if (isVIPREnabled) {
                dataObject.clientId = modalFields[CLIENT_ID].value;
                dataObject.viprProUrl = modalFields[API_URL].value;

                if (modalFields[CLIENT_SECRET].value?.toString().trim()) {
                    dataObject.clientSecret = modalFields[CLIENT_SECRET].value;
                }
            }

            if (selectedTab === tabKeys[tabKeys.length - 1]) {
                setIsLoading(true);
                if (modalMode === EDIT) {
                    editPartnerTenant(currentMenuItem!.id, dataObject)
                        .then((res: AxiosResponse<PartnerTenantsType>) => {
                            getAllTenants();
                            const successMsg = TENANT_EDIT_SUCCESS.replace(
                                '%s',
                                res.data.name
                            );
                            showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                            onCancel();
                        })
                        .catch((err: AxiosError<ErrorResponse>) => {
                            displayErrorMessage(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                } else {
                    postPartnerTenant(dataObject)
                        .then((res: AxiosResponse<PartnerTenantsType>) => {
                            getAllTenants();
                            const successMsg = TENANT_ADD_SUCCESS.replace(
                                '%s',
                                res.data.name
                            );
                            showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                            onCancel();
                        })
                        .catch((err: AxiosError<ErrorResponse>) => {
                            displayErrorMessage(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }
            } else {
                setSelectedTab(tabKeys[tabKeys.indexOf(selectedTab) + 1]);
            }
        }
    };

    const handleInputChange = (
        key: string,
        value: string,
        validations: PropertyValidationType,
        type: string
    ) => {
        const { error, helperText } = validateValues(value, validations, type);
        setModalFields(prev => ({
            ...prev,
            [key]: {
                ...prev[key],
                value,
                error,
                helperText
            }
        }));
    };

    const viprInputs = viprInputConfigs.map(
        ({ labelName, type, inputType, validations }, index) => (
            <ModalField
                key={index}
                index={index}
                inputType={inputType}
                {...modalFields[labelName]}
                disable={!isVIPREnabled}
                labelName={labelName}
                onChange={fieldValue => {
                    handleInputChange(labelName, fieldValue, validations, type);
                }}
                placeHolder={
                    inputType === PROPERTY_FIELD_TYPE_MAP.PASSWORD &&
                    modalMode === EDIT
                        ? PLACEHOLDER_PASSWORD
                        : ''
                }
                type={type}
            />
        )
    );

    const centrixInputs = centrixInputConfigs.map(
        ({ labelName, type, inputType, validations }, index) => (
            <ModalField
                key={index}
                index={index}
                inputType={inputType}
                {...modalFields[labelName]}
                disable={!isCentrixEnabled}
                labelName={labelName}
                onChange={fieldValue => {
                    handleInputChange(labelName, fieldValue, validations, type);
                }}
                placeHolder={
                    inputType === PROPERTY_FIELD_TYPE_MAP.PASSWORD &&
                    modalMode === EDIT
                        ? PLACEHOLDER_PASSWORD
                        : ''
                }
                type={type}
            />
        )
    );

    const configurationInputs = configurationInputConfigs.map(
        ({ labelName, type, inputType, placeHolder, validations }, index) => (
            <ModalField
                key={index}
                index={index}
                inputType={inputType}
                {...modalFields[labelName]}
                labelName={labelName}
                onChange={fieldValue => {
                    handleInputChange(labelName, fieldValue, validations, type);
                }}
                placeHolder={placeHolder}
                type={type}
            />
        )
    );

    const actionButton =
        selectedTab !== tabKeys[0] ? (
            <Button
                color="primary"
                onClick={() =>
                    setSelectedTab(tabKeys[tabKeys.indexOf(selectedTab) - 1])
                }
                size="large"
                startIcon={<Back />}
                style={{
                    maxWidth: '100px',
                    minWidth: '100px',
                    padding: '8px',
                    fontWeight: 'bolder'
                }}
                variant="text"
            >
                {BACK}
            </Button>
        ) : null;

    const handleTabChange = (value: string) => {
        const isValid = validateFormFields(0, setModalFields, modalFields, [
            configurationInputConfigs
        ]);
        if (!isValid) {
            setSelectedTab(value);
        }
    };

    return (
        <StyledModal
            className="wide"
            dialogActionsButton={actionButton}
            displayBtn="all"
            isDisabledSubmitBtn={hasAnyError}
            isModalOpen={modelOpen}
            onCancel={onCancel}
            onSubmit={submitButtonClick}
            submitBtnLabel={submitButtonLabel}
            title={modalTitle}
        >
            <StyledContainer minHeight={326}>
                <StyledSection noBorder>
                    <TabGroup
                        onChange={(_, value) => {
                            handleTabChange(value);
                        }}
                        sx={{
                            '& .scroll-wrapper': {
                                width: '100%'
                            },
                            '& .MuiTab-root': {
                                width: '50%'
                            }
                        }}
                        tabsItems={tabs}
                        value={selectedTab}
                    />
                    <StyledTabContent>
                        {selectedTab === 'configuration' && configurationInputs}

                        {selectedTab === 'credentials' && (
                            <>
                                <StyledCheckboxContainer>
                                    <StyledCheckboxLabel>
                                        Enable Centrix AMS
                                    </StyledCheckboxLabel>
                                    <Switch
                                        checked={isCentrixEnabled}
                                        onChange={event => {
                                            setIsCentrixEnabled(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </StyledCheckboxContainer>
                                <StyledInputsContainer
                                    isDisabled={!isCentrixEnabled}
                                >
                                    {centrixInputs}
                                </StyledInputsContainer>

                                <StyledDivider />

                                <StyledCheckboxContainer>
                                    <StyledCheckboxLabel>
                                        Enable Vipr Pro
                                    </StyledCheckboxLabel>
                                    <Switch
                                        checked={isVIPREnabled}
                                        onChange={event => {
                                            setIsVIPREnabled(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </StyledCheckboxContainer>
                                <StyledInputsContainer
                                    isDisabled={!isVIPREnabled}
                                >
                                    {viprInputs}
                                </StyledInputsContainer>
                            </>
                        )}
                    </StyledTabContent>
                </StyledSection>
            </StyledContainer>
        </StyledModal>
    );
};
