import { FC, useState } from 'react';

import { constants } from '@armis/armis-ui-library';
import { Editor, OnMount } from '@monaco-editor/react';
import { Box, Typography, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    INVALID_JSON,
    INVALID_JSON_EDIT_INFO,
    UPDATE,
    UPDATE_DASHBOARD
} from 'src/constants/LabelText';
import {
    showToast,
    TOAST_TYPE,
    displayErrorMessage
} from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { updateDashboard } from 'src/services/api.service';

import { Dashboard, HOCLoading } from '../Dashboards.types';
import {
    isJsonString,
    parseDashboardError,
    parseDashboardJson
} from '../utils';

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
    setIsLoading: HOCLoading;
    onSubmit: () => void;
    selectedDashboard: Dashboard | null;
};

const EditDashboard: FC<Props> = ({
    isModalOpen,
    onClose,
    onSubmit,
    selectedDashboard,
    setIsLoading
}) => {
    const theme = useTheme();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [dashboardJSON, setDashboardJSON] = useState(
        parseDashboardJson(selectedDashboard)
    );

    const onEditorMount: OnMount = editor => {
        editor.onDidChangeModelContent(
            debounce(() => {
                setIsSubmitDisabled(!isJsonString(editor.getValue()));
            }, 500)
        );
    };

    const handleUpdate = async () => {
        if (isJsonString(dashboardJSON)) {
            try {
                setIsLoading(true);
                const { data } = await updateDashboard(
                    selectedDashboard?.id ?? '',
                    JSON.parse(dashboardJSON ?? '')
                );
                showToast(
                    UPDATE_DASHBOARD.replace('%s', data.name),
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
                onSubmit();
            } catch (error: any) {
                displayErrorMessage(parseDashboardError(error));
            } finally {
                setIsLoading(false);
            }
        } else {
            showToast(INVALID_JSON, TOAST_TYPE.ERROR, TOAST_ID);
        }
    };

    return (
        <Modal
            className="x-wide"
            displayBtn="all"
            isDisabledSubmitBtn={isSubmitDisabled}
            isModalOpen={isModalOpen}
            onCancel={onClose}
            onSubmit={handleUpdate}
            submitBtnLabel={UPDATE}
            title={selectedDashboard?.name ?? ''}
        >
            <Box>
                <Typography
                    className="content"
                    sx={{
                        color: constants.COLOR_1,
                        marginBottom: 1
                    }}
                    variant="h5"
                >
                    {INVALID_JSON_EDIT_INFO}
                </Typography>
                <Editor
                    defaultLanguage="json"
                    defaultValue={JSON.stringify(dashboardJSON, null, 2)}
                    height="50vh"
                    onChange={value => setDashboardJSON(value ?? '')}
                    onMount={onEditorMount}
                    theme={theme.palette.mode === 'dark' ? 'vs-dark' : ''}
                />
            </Box>
        </Modal>
    );
};

export default EditDashboard;
