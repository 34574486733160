import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
    CustomHeader,
    MultiSelect,
    Pagination,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    WhiteUpload
} from '@armis/armis-ui-library';
import Editor from '@monaco-editor/react';
import { Button, Menu, useTheme } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    AMP_DASHBOARDS,
    CANCEL,
    DASHBOARD_CREATE_SUCCESS,
    DASHBOARD_DELETED_SUCCESS,
    DELETE,
    DELETE_DASHBOARD,
    DELETE_DASHBOARD_WARNING,
    IMPORT_DASHBOARD,
    INVALID_JSON_FILE,
    SEARCH_DASHBOARD_PLACEHOLDER
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    dashboardAPIMapping,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW
} from 'src/constants/TableConstants';
import { dashboardColumnsConfig } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import {
    createDashboard,
    deleteDashboard,
    getDashboards
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { StyledInput, StyledMenuItem, StyledOption } from './Dashboards.style';
import { Dashboard } from './Dashboards.types';
import EditDashboard from './Modals/EditDashboard';
import {
    actionOptions,
    availableActions,
    downloadBlobData,
    isJsonString,
    parseDashboardError,
    parseDashboardJson,
    renderActions
} from './utils';

const sortOrder: Map<number> = {};
const sortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const queryPropertiesObj: Record<string, string> = {};

createRelatedObject(
    dashboardAPIMapping,
    sortOrder,
    sortStatus,
    columnsFilterItems
);

type Props = {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
};

const DashboardComponent: FC<Props> = ({ setIsLoading }) => {
    const theme = useTheme();
    const currentUser = useSelector(selectUser);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedDashboards, setSelectedDashboards] = useState<string[]>([]);
    const [selectedAction, setSelectedAction] = useState('');
    const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | null>(
        null
    );
    const selectedDashboard = useRef<Dashboard | null>(null);
    const [refreshPage, setRefreshPage] = useState(false);
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModalOpen,
        anchorEl,
        onSelectionChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: sortOrder,
        sortStatusObj: sortStatus,
        columnsFilterItems,
        queryPropertiesObj
    });

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    const searchContainer = useMemo(
        () => (
            <SearchBar
                onChange={newValue => {
                    setQueryProperties(prevValue => ({
                        ...prevValue,
                        searchBy: newValue,
                        page: DEFAULT_PAGE,
                        size: DEFAULT_PAGESIZE
                    }));
                }}
                placeholder={SEARCH_DASHBOARD_PLACEHOLDER}
                searchValue={queryProperties.searchBy as string}
            />
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    const handleEdit = useCallback(
        (data: Dashboard) => {
            setSelectedAction(availableActions.EDIT);
            selectedDashboard.current = data;
            setModalOpen(true);
        },
        [setModalOpen]
    );

    const handleMore = useCallback((target: any, data: Dashboard) => {
        setActionsAnchor(target);
        selectedDashboard.current = data;
    }, []);

    const columnConfig = useMemo(
        () => [
            ...dashboardColumnsConfig,
            {
                field: 'actions',
                headerName: 'Actions',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                initialWidth: 120,
                cellRenderer: ({ data }: { data: Dashboard }) =>
                    renderActions(
                        currentUser,
                        () => handleEdit(data),
                        ({ currentTarget }) => handleMore(currentTarget, data)
                    ),
                sortable: false
            } as ColDef
        ],
        [currentUser, handleEdit, handleMore]
    );

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );

    const onCloseActionMenu = () => {
        setActionsAnchor(null);
    };

    const onCloseActionModal = () => {
        setModalOpen(false);
        setSelectedAction('');
        selectedDashboard.current = null;
    };

    useEffect(() => {
        const getDashboardData = async () => {
            try {
                setTableLoading(true);
                const { data } = await getDashboards(
                    convertQueryObjectToParams(queryProperties)
                );
                setDashboards(data.content);
                setTotalRows(data.totalElements);
            } catch (error: any) {
                displayErrorMessage(error);
                setDashboards([]);
                setTotalRows(0);
            } finally {
                setTableLoading(false);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                ) {
                    paginationRef.current?.resetPagination();
                }
            }
        };

        getDashboardData();
    }, [queryProperties, setTableLoading, refreshPage, gridRef]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (dashboards.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [dashboards, tableLoading, gridRef]);

    const handleDelete = async () => {
        try {
            setTableLoading(true);
            await deleteDashboard(selectedDashboard.current?.id ?? '');
            showToast(DASHBOARD_DELETED_SUCCESS, TOAST_TYPE.SUCCESS, TOAST_ID);
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setRefreshPage(prevValue => !prevValue);
            setTableLoading(false);
            onCloseActionModal();
        }
    };

    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = async (evt: any) => {
            const filedata = evt.currentTarget.result;

            if (isJsonString(filedata)) {
                try {
                    setIsLoading(true);
                    const { data } = await createDashboard(
                        JSON.parse(filedata)
                    );
                    showToast(
                        DASHBOARD_CREATE_SUCCESS.replace('%s', data.name),
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    setRefreshPage(prevValue => !prevValue);
                } catch (error: any) {
                    displayErrorMessage(parseDashboardError(error));
                } finally {
                    event.target.value = null;
                    setIsLoading(false);
                }
            } else {
                showToast(INVALID_JSON_FILE, TOAST_TYPE.ERROR, TOAST_ID);
            }
        };
    };

    const handleDownloadJson = async () => {
        const fileName = selectedDashboard.current?.name ?? 'dashboard';
        const jsonString = JSON.stringify(
            parseDashboardJson(selectedDashboard.current),
            null,
            2
        );
        const blob = new Blob([jsonString], { type: 'application/json' });
        downloadBlobData(blob, `${fileName}.json`);
    };

    const handleAction = (type: string) => {
        setSelectedAction(type);
        switch (type) {
            case availableActions.DELETE:
            case availableActions.VIEW_JSON:
                setModalOpen(true);
                break;
            case availableActions.DOWNLOAD_JSON:
                handleDownloadJson();
                break;
            default:
                break;
        }
        onCloseActionMenu();
    };

    return (
        <>
            <Header title={AMP_DASHBOARDS}>
                <ProtectedAction
                    hasPermissions={[Privileges.create]}
                    resource={Resources.settings}
                >
                    <div className="file-wrapper">
                        <label
                            className="control file-input"
                            htmlFor="file-upload"
                        >
                            <div className="input">
                                <input hidden type="file" />
                                <Button
                                    className="header-add-button"
                                    component="span"
                                    disabled={tableLoading}
                                    startIcon={<WhiteUpload />}
                                    style={{
                                        paddingLeft: '15px'
                                    }}
                                    variant="contained"
                                >
                                    {IMPORT_DASHBOARD}
                                </Button>
                            </div>
                        </label>
                        <StyledInput
                            accept=".json"
                            id="file-upload"
                            onChange={handleCapture}
                            type="file"
                        />
                    </div>
                </ProtectedAction>
            </Header>
            <div className="control table">
                <TableHeader
                    childrenLeft={searchContainer}
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedDashboards.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? 'Dashboard' : 'Dashboards'
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={{
                        component: StyledMenuPaper
                    }}
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Menu
                    anchorEl={actionsAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={onCloseActionMenu}
                    open={Boolean(actionsAnchor)}
                    PaperProps={{
                        component: StyledMenuPaper,
                        sx: {
                            marginTop: '0px !important'
                        }
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {actionOptions.map(action => (
                        <ProtectedAction
                            key={action.type}
                            hasAnyPermission={action.privileges}
                            resource={Resources.settings}
                        >
                            <StyledMenuItem
                                key={action.type}
                                onClick={() => handleAction(action.type)}
                            >
                                {action.icon}
                                <StyledOption>{action.label}</StyledOption>
                            </StyledMenuItem>
                        </ProtectedAction>
                    ))}
                </Menu>

                {modelOpen && selectedAction === availableActions.DELETE && (
                    <Modal
                        displayBtn="all"
                        isModalOpen
                        onCancel={onCloseActionModal}
                        onSubmit={handleDelete}
                        submitBtnLabel={DELETE}
                        title={DELETE_DASHBOARD}
                    >
                        <WarningModalContainer
                            text={DELETE_DASHBOARD_WARNING.replace(
                                '%s',
                                selectedDashboard.current?.name ?? ''
                            )}
                        />
                    </Modal>
                )}

                {modelOpen && selectedAction === availableActions.VIEW_JSON && (
                    <Modal
                        className="x-wide"
                        displayBtn="cancel"
                        isModalOpen
                        onCancel={onCloseActionModal}
                        onSubmit={onCloseActionModal}
                        submitBtnLabel={CANCEL}
                        title={selectedDashboard?.current?.name ?? ''}
                    >
                        <Editor
                            defaultLanguage="json"
                            defaultValue={JSON.stringify(
                                parseDashboardJson(selectedDashboard?.current),
                                null,
                                2
                            )}
                            height="50vh"
                            options={{
                                readOnly: true
                            }}
                            theme={
                                theme.palette.mode === 'dark' ? 'vs-dark' : ''
                            }
                        />
                    </Modal>
                )}

                {modelOpen && selectedAction === availableActions.EDIT && (
                    <EditDashboard
                        isModalOpen
                        onClose={onCloseActionModal}
                        onSubmit={() => {
                            setRefreshPage(prevValue => !prevValue);
                            onCloseActionModal();
                        }}
                        selectedDashboard={selectedDashboard?.current}
                        setIsLoading={setIsLoading}
                    />
                )}

                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={event => {
                        setSelectedDashboards(
                            event.api.getSelectedRows().map(({ id }) => id)
                        );
                    }}
                    rowData={dashboards}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <span>
                        <Pagination
                            ref={paginationRef}
                            onPaginationChanged={(page, size) => {
                                setQueryProperties(prevValue => ({
                                    ...prevValue,
                                    page,
                                    size
                                }));
                            }}
                            totalRowsCount={totalRows}
                        />
                    </span>
                )}
            </div>
        </>
    );
};

export const Dashboards = IsLoadingHOC(DashboardComponent);
