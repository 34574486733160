import React, { useState } from 'react';

import {
    constants,
    EyeIcon,
    Select,
    TextArea,
    TextBox,
    EyeHideIcon,
    IconButton
} from '@armis/armis-ui-library';
import {
    FormHelperText,
    FormLabel,
    InputAdornment,
    MenuItem
} from '@mui/material';
import { IoIosClose } from 'react-icons/io';
import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import { OPTIONAL } from 'src/constants/LabelText';
import { StyledField } from 'src/pages/components/ModalField/ModalField.style';

type ModalFieldTypes = {
    index: number;
    labelName: string;
    onChange: (fieldValue: string, labelName: string, type: string) => void;
    value: string | number;
    error: boolean;
    helperText: string;
    type?: string;
    items?: string[];
    disable?: boolean;
    optional?: boolean;
    inputType?: string;
    placeHolder?: string;
};

export const ModalField = ({
    index,
    labelName,
    onChange,
    value,
    error,
    disable,
    helperText,
    type = PROPERTY_FIELD_TYPE_MAP.TEXT,
    items = [],
    optional,
    inputType = 'text',
    placeHolder
}: ModalFieldTypes) => {
    let elementType;
    const [passwordType, setPasswordType] = useState(inputType);
    if (type === PROPERTY_FIELD_TYPE_MAP.TEXT) {
        const isPasswordType = inputType === PROPERTY_FIELD_TYPE_MAP.PASSWORD;

        elementType = (
            <TextBox
                autoComplete="off"
                disabled={disable}
                error={error}
                fullWidth
                helperText={helperText}
                hiddenLabel
                id={`modal-field-input-${labelName}`}
                InputProps={{
                    endAdornment: isPasswordType ? (
                        <InputAdornment
                            onClick={() => {
                                setPasswordType(prevValue =>
                                    prevValue === PROPERTY_FIELD_TYPE_MAP.TEXT
                                        ? PROPERTY_FIELD_TYPE_MAP.PASSWORD
                                        : PROPERTY_FIELD_TYPE_MAP.TEXT
                                );
                            }}
                            position="end"
                            variant="outlined"
                        >
                            <IconButton className="Icon-Hover-Effect">
                                {passwordType ===
                                PROPERTY_FIELD_TYPE_MAP.PASSWORD ? (
                                    <EyeIcon />
                                ) : (
                                    <EyeHideIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        !disable &&
                        value && (
                            <InputAdornment
                                onClick={() => onChange('', labelName, type)}
                                position="end"
                                variant="outlined"
                            >
                                <IoIosClose />
                            </InputAdornment>
                        )
                    )
                }}
                onChange={e => onChange(e.target.value, labelName, type)}
                placeholder={placeHolder}
                size="small"
                type={isPasswordType ? passwordType : inputType}
                value={value as string}
                variant="outlined"
            />
        );
    } else if (type === PROPERTY_FIELD_TYPE_MAP.DROPDOWN) {
        elementType = (
            <>
                <Select
                    disabled={disable}
                    inputProps={{
                        id: `modal-field-input-${labelName}`
                    }}
                    MenuProps={{
                        sx: { zIndex: 10002 }
                    }}
                    onChange={e =>
                        onChange(e.target.value as string, labelName, type)
                    }
                    sx={{
                        width: '100%',
                        border: error ? `1px solid ${constants.COLOR_5}` : ''
                    }}
                    value={value}
                    variant="outlined"
                >
                    {items.map((element, index1) => (
                        <MenuItem key={index1} value={index1}>
                            {element}
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText error>{helperText}</FormHelperText>}
            </>
        );
    } else if (type === PROPERTY_FIELD_TYPE_MAP.TEXTAREA) {
        elementType = (
            <>
                <TextArea
                    className="modal-text-field"
                    id={`modal-field-input-${labelName}`}
                    maxRows="5"
                    minRows="5"
                    onChange={e => {
                        onChange(e.target.value as string, labelName, type);
                    }}
                    style={{
                        width: '100%',
                        maxWidth: '100%',
                        maxHeight: '11.5rem',
                        border: helperText && `1px solid ${constants.COLOR_5}`,
                        fontFamily: constants.PROXIMANOVA_W08_REGULAR,
                        fontSize: '1.4rem'
                    }}
                    value={value as string}
                />
                {helperText && (
                    <FormHelperText error>{helperText}</FormHelperText>
                )}
            </>
        );
    }
    return (
        <StyledField key={index}>
            <FormLabel htmlFor={`modal-field-input-${labelName}`}>
                {labelName}{' '}
                {optional ? (
                    <span
                        style={{
                            fontSize: '1rem',
                            fontFamily: constants.PROXIMANOVA_W08_THIN_REGULAR
                        }}
                    >
                        {OPTIONAL}
                    </span>
                ) : null}
            </FormLabel>
            {elementType || null}
        </StyledField>
    );
};
