import { CHECKBOX_STATUS } from '@armis/armis-ui-library';
import { Map, FilterItems } from 'src/types/CommonTypes';

export const NO_DATA_TO_SHOW = 'No data to show';
export const DEFAULT_PAGESIZE = 50;
export const DEFAULT_PAGE = 0;

// Audit log
export const auditLogAPIMapping: Map<string | Map<string>> = {
    actiontype: 'Trigger',
    action: 'Action',
    username: 'User',
    userip: 'User IP',
    createdon: 'Time',
    info: 'Info'
};

// Partners
export const partnersAPIMapping = {
    name: 'Name',
    salesForceId: 'Salesforce ID',
    status: 'Status',
    regionDto: 'Region',
    adminEmail: 'Admin Email',
    adminFirstName: 'Admin Firstname',
    adminLastName: 'Admin Lastname',
    tenantCount: 'Tenant Count',
    partnerActions: 'Actions'
};

// Partner Tenants
export const partnerTenantsAPIMapping = {
    name: 'Name',
    tenantType: 'Tenant Type',
    createddate: 'Associated Date',
    createdby: 'Associated By',
    tenantUrl: 'Tenant URL',
    partnerTenantActions: 'Actions'
};

// User Management
export const userManagementAPIMapping: Map<string | Map<string>> = {
    firstname: 'Name',
    username: 'Email',
    roles: 'Roles',
    lastloggedin: 'Last Login Time'
};

// Aggregated View
export const AggregatedViewAPIMapping: Map<string | Map<string>> = {
    name: 'Partner Name',
    region: 'Host Region',
    tenantCount: 'Tenants',
    device: 'Devices',
    site: 'Sites',
    activePolicy: 'Active Policies',
    alert: 'Alerts',
    integration: 'Integrations',
    collector: 'Collectors',
    logins: 'Logins',
    lastAccessDate: 'Last Login Time'
};

// Tenant Aggregation
export const tenantAggregationAPIMapping: Map<string | Map<string>> = {
    name: 'Tenant Name',
    device: 'Devices',
    site: 'Sites',
    alert: 'Alerts',
    activePolicy: 'Active Policies',
    Integrations: {
        activeIntegration: 'Active',
        inactiveIntegration: 'Inactive',
        draftIntegration: 'Draft',
        errorIntegration: 'Error'
    },
    Collectors: {
        activeCollector: 'Active',
        offlineCollector: 'Offline',
        initializingCollector: 'Initializing',
        pendingActivationCollector: 'Pending Activation'
    },
    tenantUrl: 'Tenant URL'
};
export const createRelatedObject = (
    APIMappingObject: Map<string | Map<string>>,
    sortOrder: Map<number>,
    sortStatus: Map<string>,
    columnFilter: FilterItems[],
    hideColumns?: String[]
) => {
    Object.keys(APIMappingObject).forEach(element => {
        const keyValue = APIMappingObject[element];
        if (typeof keyValue === 'string') {
            sortOrder[element] = -1;
            sortStatus[element] = '';
            columnFilter.push({
                id: element,
                label: keyValue,
                labelRender: <span>{keyValue}</span>,
                checkStatus: hideColumns?.includes(element)
                    ? CHECKBOX_STATUS.UNCHECKED
                    : CHECKBOX_STATUS.CHECKED
            });
        } else {
            Object.keys(keyValue).forEach(element1 => {
                sortOrder[element1] = -1;
                sortStatus[element1] = '';
                columnFilter.push({
                    id: element1,
                    label: `${element} ${keyValue[element1]}`,
                    labelRender: (
                        <span>{`${element} ${keyValue[element1]}`}</span>
                    ),
                    checkStatus: hideColumns?.includes(element1)
                        ? CHECKBOX_STATUS.UNCHECKED
                        : CHECKBOX_STATUS.CHECKED
                });
            });
        }
    });
};

export const reportTemplateAPIMapping: Map<string> = {
    name: 'Name',
    title: 'Title',
    tags: 'Tags',
    createdby: 'Created By',
    updateddate: 'Last Modified'
};

export const dashboardAPIMapping: Map<string> = {
    name: 'Name',
    createdby: 'Created By',
    updateddate: 'Last Modified',
    actions: 'Actions'
};

export const alertRedirectLink = '/entities/alerts';
export const activeIntegrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3AActive';
export const inActiveIntegrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3AInactive';
export const draftIntegrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3ADraft';
export const integrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3A"Connection+Error"';
export const collectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3AOffline';
export const activeCollectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3AActive';
export const pendingActivationCollectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3A"Pending+activation"';
export const initializingCollectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3AInitializing';
export const sitesRedirectLink = '/settings/sites';
export const policyRedirectLink = '/entities/policy';
export const deviceRedirectLink = '/inventory/devices';
