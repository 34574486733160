import { constants } from '@armis/armis-ui-library';
import { MenuItem, styled } from '@mui/material';

export const StyledActions = styled('div')`
    display: flex;
    column-gap: 20px;
    margin-top: 5px;
`;

export const StyledInput = styled('input')`
    display: none;
`;

export const StyledOption = styled('div')`
    line-height: 28px;
    padding: 4px 10px;
`;

export const StyledMenuItem = styled(MenuItem)`
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_26
            : constants.COLOR_GENERAL_24};
`;
