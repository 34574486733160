import { analytics, settings, tenants, logo_amm } from 'src/assets/images';

import { Privileges, Resources } from './CommonConstants';
import {
    ANALYTICS_LABEL,
    NOTIFICATIONS_LOGGING,
    USER_MANAGEMENT_LABEL,
    PARTNERS as PARTNERS_LABEL,
    SETTINGS_LABEL,
    USERS_MANAGEMENT_HEADER,
    ROLE_MANAGEMENT as ROLE_MANAGEMENT_LABEL,
    AUDIT_LOG_HEADER,
    CUSTOM_SETTINGS_TITLE,
    AMP_DASHBOARDS,
    AMP_REPORT_TEMPLATES
} from './LabelText';

export const LOGIN = '/login';
export const HOME = '/';
export const MY_PROFILE = '/my-profile';
export const PARTNERS = '/partners';
export const SETTINGS = '/settings';
export const REPORTS = '/reports';
export const ANALYTICS = '/analytics';
export const AGGREGATED_VIEW = 'aggregated-view';
export const ROLE_MANAGEMENT = 'role-management';
export const USER_MANAGEMENT = 'user-management';
export const AUDIT_LOG = 'audit-log';
export const DASHLETS_ASQ = 'partner-asqs';
export const AMP_DASHBOARD = 'amp-dashboards';
export const AMP_REPORTS = 'reports';

// SideNavbar constants
export const sideNavbarProps = {
    logoSVG: logo_amm,
    logoClickRedirect: HOME,
    items: [
        {
            itemName: ANALYTICS_LABEL,
            itemSVG: analytics,
            routePath: HOME,
            isSSOConfigRequired: false
        },
        {
            itemName: PARTNERS_LABEL,
            itemSVG: tenants,
            routePath: PARTNERS,
            isSSOConfigRequired: false,
            resource: Resources.partner,
            requiredPermission: [Privileges.read]
        },
        {
            itemName: SETTINGS_LABEL,
            itemSVG: settings,
            subMenuItems: [
                {
                    itemTitle: USER_MANAGEMENT_LABEL,
                    itemContent: [
                        {
                            itemName: USERS_MANAGEMENT_HEADER,
                            routePath: `${SETTINGS}/${USER_MANAGEMENT}`,
                            resource: Resources.users,
                            requiredPermission: [Privileges.read]
                        },
                        {
                            itemName: ROLE_MANAGEMENT_LABEL,
                            routePath: `${SETTINGS}/${ROLE_MANAGEMENT}`,
                            resource: Resources.roles,
                            requiredPermission: [Privileges.read]
                        }
                    ]
                },
                {
                    itemTitle: NOTIFICATIONS_LOGGING,
                    itemContent: [
                        {
                            itemName: AUDIT_LOG_HEADER,
                            routePath: `${SETTINGS}/${AUDIT_LOG}`,
                            resource: Resources.settings,
                            requiredPermission: [Privileges.auditLog]
                        }
                    ]
                },
                {
                    itemTitle: CUSTOM_SETTINGS_TITLE,
                    itemContent: [
                        {
                            itemName: AMP_DASHBOARDS,
                            routePath: `${SETTINGS}/${AMP_DASHBOARD}`,
                            resource: Resources.settings,
                            requiredPermission: [Privileges.read]
                        },
                        {
                            itemName: AMP_REPORT_TEMPLATES,
                            routePath: `${SETTINGS}/${AMP_REPORTS}`,
                            resource: Resources.settings,
                            requiredPermission: [Privileges.read]
                        }
                    ]
                }
            ]
        }
    ]
};

export const defaultRouteConstants: { [key: string]: string } = {
    [SETTINGS]: `${SETTINGS}/${USER_MANAGEMENT}`
} as const;
