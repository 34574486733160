import { constants } from '@armis/armis-ui-library';
import { css, Divider, styled } from '@mui/material';
import { Modal } from 'src/pages/components/Modal';

export const StyledModal = styled(Modal)`
    .MuiDialogContent-root {
        padding-top: 0;
    }
`;

export const StyledTabContent = styled('div')`
    margin-top: 10pt;
`;

export const StyledCheckboxContainer = styled('div')`
    display: flex;
    gap: 16px;
    margin-bottom: 10pt;
`;

export const StyledCheckboxLabel = styled('span')`
    font-family: ${constants.PROXIMANOVA_W08_REGULAR};
    font-size: 1.4rem;
    font-weight: bold;
`;

export const StyledDivider = styled(Divider)`
    margin: 12pt 0;
`;

export const StyledInputsContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'isDisabled'
})<{ isDisabled?: boolean }>`
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
            cursor: default;
            opacity: 0.8;
        `}
`;
