export const LOGIN = '/login';
export const TWO_FACTOR_AUTH_VERIFY = '/factor/verify/user';
export const USER = '/user';
export const VERIFY_PASSWORD = '/verifypassword';
export const CHANGE_PASSWORD = '/changepassword';
export const LOGOUT = '/logout';
export const SSO = '/sso';
export const QUERY_PREFIX = '?r=';

// RBAC
export const GET_ROLES = '/roles';
export const CREATE_ROLE = '/roles';
export const DELETE_ROLES = '/roles/_bulk';
export const UPDATE_ROLE = '/roles';
export const GET_ROLE_STRUCTURE = '/roles/definition';

export const TWO_FACTOR_TIMEOUT = 5 * 60 * 1000;
export const TOAST_ID = 'a98db973kwl8';

// AuditLog
export const GET_AUDIT_LOGS = '/auditlogs';
export const EXPORT_AUDIT_LOGS = '/auditlogs/export';
export const FILTER_AUDIT_LOGS = '/auditlogs/uniquePropsValues';

// Partners
export const PARTNERS_ENDPOINT = '/partners';
export const GET_REGIONS = '/regions';
export const PARTNER_USERS = 'users';

// Partner Tenants
export const PARTNER_TENANTS_ENDPOINT = '/tenants/partners';
export const PARTNER_TENANTS_FORM_ENDPOINT = '/tenants';
// User Management
export const USERS = '/users';
export const DELETE_BULK_USER = '/users/_bulk';

// Saml
export const SAML2_ENDPOINT = '/saml2/authenticate/saml';

// Aggregation View
export const AGGREGATIONS = '/aggregations';

// Report Template
export const GET_REPORT_TEMPLATE = '/reports';

// AMP Dashboard
export const AMP_DASHBOARD_ENDPOINT = '/ampDashboards';
