import { MouseEventHandler } from 'react';

import {
    IconButton,
    IconDelete,
    IconPencil,
    MoreAction,
    Tooltip,
    View
} from '@armis/armis-ui-library';
import { cloneDeep } from 'lodash';
import { LuDownload } from 'react-icons/lu';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    DELETE,
    DOWNLOAD_JSON,
    EDIT,
    GENERIC_DASHBOARD_ERROR,
    VIEW_JSON
} from 'src/constants/LabelText';
import { isActionHasPermissions } from 'src/helpers/utility';
import { User } from 'src/types/APIResponseTypes';

import { StyledActions } from './Dashboards.style';
import { Dashboard } from './Dashboards.types';

export const availableActions = {
    EDIT,
    DELETE,
    VIEW_JSON,
    DOWNLOAD_JSON
};

export const actionOptions = [
    {
        type: availableActions.DELETE,
        icon: <IconDelete />,
        label: DELETE,
        privileges: [Privileges.delete]
    },
    {
        type: availableActions.VIEW_JSON,
        icon: <View height={14} width={14} />,
        label: VIEW_JSON,
        privileges: [Privileges.read]
    },
    {
        type: availableActions.DOWNLOAD_JSON,
        icon: <LuDownload />,
        label: DOWNLOAD_JSON,
        privileges: [Privileges.read]
    }
];

export const renderActions = (
    currentUser: User,
    handleEdit: () => void,
    handleMore: MouseEventHandler
) => {
    const hasEditAccess = isActionHasPermissions(
        currentUser,
        Resources.settings,
        [Privileges.edit]
    );
    const hasMoreAccess = isActionHasPermissions(
        currentUser,
        Resources.settings,
        [Privileges.delete, Privileges.read]
    );

    return (
        <StyledActions>
            <Tooltip
                arrow
                placement="bottom"
                title={<div className="tooltip-arrow-text">{EDIT}</div>}
            >
                <IconButton
                    className="Icon-Hover-Effect"
                    disabled={!hasEditAccess}
                    onClick={handleEdit}
                    size="small"
                >
                    <IconPencil height={16} width={16} />
                </IconButton>
            </Tooltip>
            <IconButton
                className="Icon-Hover-Effect"
                disabled={!hasMoreAccess}
                onClick={handleMore}
                size="small"
            >
                <MoreAction />
            </IconButton>
        </StyledActions>
    );
};

export const downloadBlobData = (data: any, name: string) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};

export const parseDashboardError = (error: any) => {
    if (error.response.data?.errors) {
        if (error.response.data.errors?.name) {
            return { message: `Name ${error.response.data.errors.name}` };
        }
        if (error.response.data.errors?.ampDashboardColumnDtos) {
            return {
                message: `Amp Dashboard Column ${error.response.data.errors.ampDashboardColumnDtos}`
            };
        }
        return {
            message: GENERIC_DASHBOARD_ERROR
        };
    }
    if (
        typeof error.response.data?.message === 'string' &&
        error.response.data.message.includes('An error caused')
    )
        return { message: GENERIC_DASHBOARD_ERROR };

    return error;
};

export const isJsonString = (str: any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const parseDashboardJson = (dashboard: Dashboard | null) => {
    if (!dashboard) return '';

    const parsedDashboard = cloneDeep(dashboard) as Partial<Dashboard>;
    delete parsedDashboard.id;
    delete parsedDashboard.createdby;
    delete parsedDashboard.updateddate;
    delete parsedDashboard.updatedby;

    return parsedDashboard as unknown as string;
};
